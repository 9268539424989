import React from "react";

import { StyleSheet, css } from "aphrodite";

import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

const styles = StyleSheet.create({
  researchBox: {
    marginTop: "1em",
    marginBottom: "2.5em"
  },
  subtext: {
    fontStyle: "italic"
  }
});

export default function Research() {
  return (
    <div
      style={{
        background: "url(bg2.png)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat"
      }}
    >
      <Container className="pageContainer" id="research">
        <Row>
          <Col md={{ span: 4 }}>
            <h2 style={{ fontSize: 36 }}>Research</h2>
          </Col>
        </Row>
        <Row style={{ marginTop: "2em" }}>
          <Col md={{ span: 6 }}>
            <h3 style={{ fontSize: 24 }}>Current</h3>
            <div style={{ borderBottom: "1px solid #000" }}></div>
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 6 }}>
          <div className={css(styles.researchBox)}>
              <p>
                Gerontocracy
              </p>
              <p className={css(styles.subtext)}>
                Recipient of Capitan Jonathan Fay Prize and Hoopes Prize for Best Thesis at Harvard College, 2023. 
                Harvard College Research Program (HCRP) grant recipient, 2022.
              </p>
              <p>
              Respect for elders is a central pillar of many social structures, where norms range from gerontocracy (rule by elders) and filial piety to geronticide. 
              Despite their importance, these beliefs are not well understood. What causes variation in attitudes toward elders, and what consequences do these norms have today? 
              To answer this question, I first describe the historical and contemporary distribution of gerontocracy and attitudes toward elders across societies. 
              To understand historical norms, I create a measure of ancestral attitudes based on 1,200 folklore motifs spanning 558 different cultures. Next, I turn to the origins of elder norms. 
              Using historical environmental data on climatic stability and subsistence complexity, I show the importance of elders in society is increasing in both the likelihood their advice 
              remains relevant in future generations and the implicit cost of ignoring advice and ``reinventing the wheel''. I finally examine present-day consequences of this variation. 
              I find historical support for elders explains both cross- and within-country treatment of elders today, as reflected in old-age mortality and implicit bias tests. 
              I finally test the influence of historical gerontocracy at the polling both, using data on the age of elected leaders within countries. 
              I find historical positive norms toward elders predict increased average ages of elected leaders as well as more fragile democracies.
              </p>
            </div>
          </Col>
        </Row>
        <Row style={{ marginTop: "2em" }}>
          <Col md={{ span: 6 }}>
            <h3 style={{ fontSize: 24 }}>Past</h3>
            <div style={{ borderBottom: "1px solid #000" }}></div>
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 6 }}>

          <div className={css(styles.researchBox)}>
              <p>
                Trade Agreements and Protectionism's Shadow: Evidence from Japan
                (with{" "}
                <a
                  href="https://sites.google.com/view/ryomakioka/home"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Makioka Ryo
                </a>
                ), RIETI Discussion Paper Series (
                <a
                  href="https://www.dropbox.com/s/ibf8bwddi6o1jgn/Saenger_Makioka_DP_Draft_v2-3.pdf?dl=0"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  draft
                </a>
              ), Presentations @ FRB International Finance, Japan Society of International Economics (
                <a
                  href="https://www.dropbox.com/s/ibf8bwddi6o1jgn/Saenger_Makioka_DP_Draft_v2-3.pdf?dl=0"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  english slides
                </a>
              ).
              </p>
              <p className={css(styles.subtext)}>
                Grants: Reischauer Institute of Japanese Studies (Summer
                Research Grant), Harvard University Asia Center (Winter Research
                Grant)
              </p>
            </div>
            <div className={css(styles.researchBox)}>
              <p>
                Unmasking Tourism in Venice: How tourism dynamics unfold within
                the built environment (MIT Civic Data Design Lab),{" "}
                <a
                  href="http://overtourismvenice.mit.edu"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  website
                </a>
              , press in{" "}
              <a
                href="https://www.urbanet.info/an-epidemic-might-cure-venice/"
                target="_blank"
                rel="noopener noreferrer"
              >
                URBANET
              </a>
              {" "}(a publication of the German Federal Ministry for Economic Cooperation and Development)
              </p>
              <p className={css(styles.subtext)}>
                Grant: Harvard Center for European Studies (Thesis Grant)
              </p>
            </div>
          </Col>
        </Row>
        <Row>
          <div>
            (<a href="#top">Back</a>)
          </div>
        </Row>
      </Container>
    </div>
  );
}
