import React from "react";

import { StyleSheet, css } from "aphrodite";

import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

const styles = StyleSheet.create({
  subheader: {
    paddingBottom: "5px",
    borderBottom: "1px solid #000"
  },
  textBox: {
    padding: "1rem 0"
  },
  navLink: {
    fontWeight: 600,
    textDecoration: "underline",
    paddingBottom: "2.5rem"
  },
  socialLink: {}
});

export default function Top(props) {
  return (
    <div
      style={{
        background: "url(bg1.png)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat"
      }}
    >
      <Container className="pageContainer" id="top">
        <Row>
          <Col md={{ span: 4 }}>
            <h1
              style={{ textAlign: "right", fontSize: 64, fontWeight: "bold" }}
            >
              Leo Saenger
            </h1>
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 2, offset: 2 }}>
            <div
              style={{ width: "100%", textAlign: "right"}}
            >
            Political Economy</div>
            <div
              style={{ width: "100%", textAlign: "right"}}
            >
            History</div>
            <div
              style={{ width: "100%", textAlign: "right"}}
            >
            Development</div>
          </Col>
        </Row>
        <Row style={{ marginTop: "8em", marginBottom: "1em" }}>
          <Col md={4}>
            <div className={css(styles.subheader)}>
              <h2>
                <span style={{ textDecoration: "underline", fontWeight: 'normal', cursor: "pointer" }} onClick={props.handleAboutClick}>
                  About
                </span>
              </h2>
            </div>
            <div className={css(styles.textBox)}>
              <p>
                I am a Ph.D. student in Business Economics at Harvard University and Harvard Business School. 
              </p>
              <p>
                Previously, I worked as a investment analyst at Bracebridge Capital, where I focused on commercial real estate and CMBX. 
                Before that, I attended Harvard College, where I graduated <em>magna cum laude</em> with a degree in Applied Mathematics with a focus on Economics.
              </p>
            </div>
          </Col>
          <Col md={4}>
            <div className={css(styles.subheader)}>
              <h2><a href="#research">Research</a></h2>
            </div>
            <div className={css(styles.textBox)}>
              <p>
                I am interested in the intersections between political economy, history, and development economics. 
              </p>
              <p>
                Currently, I'm working on the evolution and persistence
                of gerontocracy and early state formation in Southeast Asia. 
              </p>
            </div>
          </Col>
          <Col md={4}>
            <div className={css(styles.subheader)}>
              <h2><a href="https://saenger.substack.com" rel="noopener noreferrer" target="_blank">Blog</a></h2>
            </div>
            <div className={css(styles.textBox)}>
              <p>
                I occasionally publish blog posts on topics ranging from the political economy of housing, where to eat in Tokyo,
                GLP-1s, bank regulation, and predicting (and getting wrong) inflation expectations.  
              </p>
              <p>
                You can read the blog <a href="https://saenger.substack.com" rel="noopener noreferrer" target="_blank">here</a>.
              </p>
            </div>
          </Col>
        </Row>
        <Row style={{ marginBottom: "2em" }}>
          <Col style={{ textAlign: "center" }}>
            <span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={props.handleAboutClick}
            >
              about me
            </span>
            {" "}•{" "}
            <span className="socialLink">
              <a
                href="https://www.dropbox.com/scl/fi/e95d8nga5zxzwmyef8cn7/Leo_CV_Economics.pdf?rlkey=9r8ls4wfkhrxfvmp9fyjdo0jf&dl=0"
                rel="noopener noreferrer"
                target="_blank"
              >
                full cv
              </a>
            </span>{" "}
          </Col>
        </Row>
        <Row>
          <Col style={{ textAlign: "center" }}>
            <h2> Contact: </h2>
          </Col>
        </Row>
        <Row>
          <Col style={{ textAlign: "center" }}>
            <span className="socialLink">
              <a
                href="https://twitter.com/saengerleo"
                rel="noopener noreferrer"
                target="_blank"
              >
                twitter
              </a>
            </span>{" "}
            •{" "}
            <span className="socialLink">
              <a
                href="https://www.linkedin.com/in/leosaenger/"
                rel="noopener noreferrer"
                target="_blank"
              >
                linkedin
              </a>
            </span>{" "}
            •{" "}
            <span className="socialLink">
              <a href="mailto:leosaenger@gmail.com">email</a>
            </span>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
